import React, { Component, Fragment } from "react";
import {  fetchGet, fetchPut } from "../../../utils/Fetch";
import { withRouter, Redirect } from "react-router-dom";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import DatePicker from "react-datepicker";

const initialState = {
  ID_ASIGNACION_COMBUSTIBLE: "",
  ID_TARJETA: "",
  ID_COMBUSTIBLE: "",
  GALONES_COMBUSTIBLE:null,
  FECHA_ASIGNACION: new Date(),
  FECHA_VENCE: new Date(),
  ID_USUARIO: 1,
  ID_EMPRESA: 1,
  ESTADO: true,
  AUTORIZACION: "",
  ID_UNIDAD: "",
  ACTIVA: true,
  VALIDARODOMETRO: false,
  ODOTOMETRO: "",
  Unidad:[]
};



class AsignacionCombustibleEditar extends Component {
  state = {
    ...initialState,
  };

  clearState = () => {
    this.setState({
      ...initialState,
    });
  };

  UpdateState = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  validarForm = () => {
    const {
      ID_TARJETA,
      ID_COMBUSTIBLE,
      GALONES_COMBUSTIBLE,
      FECHA_ASIGNACION,
      ID_USUARIO,
      ID_EMPRESA,
      AUTORIZACION,
      FECHA_VENCE,
      ID_UNIDAD,
      ODOTOMETRO,
    } = this.state;
    // const odotometro_real=(parseInt(ODOTOMETRO)>=parseInt(this.state.Unidad.ULTIMO_ODOMETRO_UNIDAD));

    const noValido =
      !ID_TARJETA ||
      !ID_COMBUSTIBLE ||
      !GALONES_COMBUSTIBLE ||
      !FECHA_ASIGNACION ||
      !ID_USUARIO ||
      !ID_EMPRESA ||
      !AUTORIZACION ||
      !FECHA_VENCE ||
      !ID_UNIDAD ||
      !ODOTOMETRO;
    return noValido;
  };

  CrearAsignacionCombustible = async (e) => {
    e.preventDefault();


    const { ID_TARJETA, ID_COMBUSTIBLE, GALONES_COMBUSTIBLE, 
    FECHA_ASIGNACION, FECHA_VENCE, ID_USUARIO, 
    ID_EMPRESA, ESTADO, AUTORIZACION, ID_UNIDAD, 
    ACTIVA, VALIDARODOMETRO, ODOTOMETRO }=await this.state;

    const envioDatos={
      ID_TARJETA, ID_COMBUSTIBLE, GALONES_COMBUSTIBLE, 
      FECHA_ASIGNACION, FECHA_VENCE, ID_USUARIO, 
      ID_EMPRESA, ESTADO, AUTORIZACION, ID_UNIDAD, 
      ACTIVA, VALIDARODOMETRO, ODOTOMETRO 
    };
    const data = await fetchPut(
      `${process.env.REACT_APP_SERVER}/api/asignacioncombustible/${this.state.ID_ASIGNACION_COMBUSTIBLE}`,
      envioDatos
    );

    this.setState({ data: data.data });
    alert(data.message);
    this.props.history.push("/asignacioncombustible");
  };

  async componentDidMount() {
    const { id } = this.props.match.params;

    const data = await fetchGet(
      `${process.env.REACT_APP_SERVER}/api/asignacioncombustible/${id}`
    );
    this.setState({ ...data.data[0] });

    const dataAsignacionTarjeta = await fetchGet(
      `${process.env.REACT_APP_SERVER}/api/asignaciontarjetaunidad`
    );

    const dataTarjeta = await fetchGet(
      `${process.env.REACT_APP_SERVER}/api/tarjeta/asingacion/true`
    );
    const datacombustible = await fetchGet(
      `${process.env.REACT_APP_SERVER}/api/combustible`
    );
    const datauni = await fetchGet(
      `${process.env.REACT_APP_SERVER}/api/unidad/asingacion/${true}`
    );

    // const Roles=dataRol.data;
    // const Rol=Roles.find(rol=>rol.ID_ROL===data.data[0].ID_ROL)
//     console.log(data.data[0])

// console.log(this.state.ID_TARJETA,
//   data.data[0].ID_COMBUSTIBLE,
//   data.data[0].ID_UNIDAD)

   const Tarjetas=dataTarjeta.data;
   const Combustibles=datacombustible.data;
   const Unidades= datauni.data;
   const Tarjeta=Tarjetas.find(dat=>dat.ID_TARJETA===this.state.ID_TARJETA)
   const Combustible=Combustibles.find(dat=>dat.ID_COMBUSTIBLE===this.state.ID_COMBUSTIBLE)
   const Unidad=Unidades.find(dat=>dat.ID_UNIDAD===this.state.ID_UNIDAD)


    this.setState({ Tarjetas,
      Combustibles,
      Unidades,
      Tarjeta,
      Combustible,
      Unidad,   AsignacionTarjeta: dataAsignacionTarjeta.data
    });
  }
  updateStateSelectUnidad = (Unidad) => {

    const NUMERO_TARJETA = this.state.AsignacionTarjeta.find(dat=>dat.ID_UNIDAD===Unidad.ID_UNIDAD)
    
    
    
    
    this.setState({ 
      Unidad, ID_TARJETA: NUMERO_TARJETA.ID_TARJETA,ID_UNIDAD:Unidad.ID_UNIDAD, 
      AUTORIZACION:NUMERO_TARJETA.AUTORIZACION, 
      NOMBRE_UNIDAD:NUMERO_TARJETA.NOMBRE_UNIDAD,
      NUMERO_TARJETA:NUMERO_TARJETA.NUMERO_TARJETA
      });
     
      };
      updateStateSelectCombustibles = (Combustible) => {
        this.setState({ Combustible, ID_COMBUSTIBLE: Combustible.ID_COMBUSTIBLE });
      };
    
      // updateStateSelectUnidad = (Unidad) => {
      //   this.setState({ Unidad, ID_UNIDAD: Unidad.ID_UNIDAD });
      // };
    
      cambioFecha=(FECHA_ASIGNACION)=>{
        let FECHA_VENCE= new Date(FECHA_ASIGNACION)
        FECHA_VENCE= FECHA_VENCE.setHours(FECHA_VENCE.getHours()+24);
    
    
    
       this.setState({ FECHA_ASIGNACION,FECHA_VENCE:new Date(FECHA_VENCE)})
      // //  console.log(FECHA_VENCE.toUTCString())
       
      // //  console.log(FECHA_VENCE.toGMTString())
     
      // // var d = new Date(FECHA_ASIGNACION); 
      // var n = FECHA_VENCE.toUTCString(); 
      // console.log(n)
      }
  render() {
    const redireccion = this.props.Access("ModificarAsignacionCombustible") ? (
      ""
    ) : (
      <Redirect to="/login" />
    );
    const mensaje = this.props.modificar ? (
      "Editar Asignacion Combustible"
    ) : (
      "Detalles de Asignacion Combustible"
    );
    return (
      <Fragment>
        {redireccion}
        <h1 className="text-center mb-5">{mensaje}</h1>

        <div className="row justify-content-center">
          <form
            className="col-md-8 col-sm-12"
            onSubmit={(e) => this.CrearAsignacionCombustible(e)}
          >
           <div className="form-group">
              <label>Unidad:</label>
              <Select
                onChange={this.updateStateSelectUnidad}
                options={this.state.Unidades}
                isMulti={false}
                isDisabled={true}
                components={makeAnimated()}
                placeholder={"Seleccione la Unidad"}
                getOptionLabel={(options) => options.NOMBRE_UNIDAD}
                getOptionValue={(options) => options.ID_UNIDAD}
                value={this.state.Unidad}
              />
            </div>

            <div className="form-group">
              <label>Tarjeta:</label>
              {/* <Select
                onChange={this.updateStateSelectUnidad}
                options={this.state.Unidades}
                isMulti={false}
                components={makeAnimated()}
                placeholder={"Seleccione al Unidad"}
                getOptionLabel={(options) => options.NOMBRE_UNIDAD}
                getOptionValue={(options) => options.ID_UNIDAD}
                value={this.state.Unidad}
              /> */}
              <input
                type="text"
                name="NUMERO_TARJETA"
                className="form-control"
                placeholder="NUMERO_TARJETA"
                onChange={this.UpdateState}
                readOnly
                defaultValue={this.state.NUMERO_TARJETA}
              />
            </div>
            <div className="form-group">
              <label>Combustible:</label>
              <Select
                onChange={this.updateStateSelectCombustibles}
                options={this.state.Combustibles}
                isMulti={false}
                components={makeAnimated()}
                placeholder={"Seleccione el Combustible"}
                getOptionLabel={(options) => options.NOMBRE_COMBUSTIBLE}
                getOptionValue={(options) => options.ID_COMBUSTIBLE}
                value={this.state.Combustible}
                isDisabled={!this.props.modificar}
                
              />
            </div>

            <div className="form-group">
              <label>Galones Combustible</label>
              <input
                type="number"
                name="GALONES_COMBUSTIBLE"
                className="form-control"
                placeholder="Numero de Galones"
                  readOnly={!this.props.modificar}
                onChange={this.UpdateState}
                defaultValue={this.state.GALONES_COMBUSTIBLE}
              />
            </div>
            <div className="form-group">
              <label>Validar Odometro</label>
              <input
                type="checkbox"
                checked={this.state.VALIDARODOMETRO}
                name="VALIDARODOMETRO"
                className="form-control checkbox"
                disabled={!this.props.modificar}
                placeholder="Numero de Galones"
                onChange={() => this.setState({VALIDARODOMETRO:!this.state.VALIDARODOMETRO})}
                defaultValue={this.state.VALIDARODOMETRO}
              />
            </div>
            <div className="form-group">
              <label>Odometro</label>
              <input
                type="number"
                name="ODOTOMETRO"
                className="form-control"
                placeholder="ODOTOMETRO"
                  readOnly={!this.props.modificar}
                onChange={this.UpdateState}
                defaultValue={this.state.ODOTOMETRO}
              />
            </div>
            <div className="form-group">
              <label>Activa</label>
              <input
                type="text"
                name="ACTIVA"
                className="form-control"
                placeholder="Activa"
                readOnly
                onChange={this.UpdateState}
                defaultValue={(this.state.ACTIVA===true) ?'SI':'NO'}
              />
            </div>

            <div className="form-group">
              <label>Fecha Asignacion</label>
            </div>
            <div className="form-group">
              <DatePicker
                selected={new Date(this.state.FECHA_ASIGNACION)}
                onChange={this.cambioFecha}
                  readOnly
                showTimeSelect
                timeIntervals={5}
                timeFormat="HH:mm"
                dateFormat="yyyy/MM/dd HH:mm"
              />
            </div>
            <div className="form-group">
              <label>Fecha Vencimiento</label>
            </div>
            <div className="form-group">
              <DatePicker
                selected={new Date(this.state.FECHA_VENCE)}
                onChange={(FECHA_VENCE) => this.setState({ FECHA_VENCE })}
                  readOnly
                showTimeSelect
                timeIntervals={5}
                timeFormat="HH:mm"
                dateFormat="yyyy/MM/dd HH:mm"
              />
            </div>
            <div className="form-group">
              <label>Autorizacion</label>
              <input
                type="text"
                name="AUTORIZACION"
                className="form-control"
                placeholder="AUTORIZACION"
                onChange={this.UpdateState}
                readOnly
                defaultValue={this.state.AUTORIZACION}
              />
            </div>
            {this.props.modificar &&(
              <button
                //disabled={this.validarForm()}
                type="submit"
                className="btn btn-success float-right"
              >
                Editar Asignacion
              </button>
              )}
          </form>
        </div>
      </Fragment>
    );
  }
}

export default withRouter(AsignacionCombustibleEditar);
